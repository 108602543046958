/* You can add global styles to this file, and also import other style files */
@import "./../node_modules/bootstrap/scss/bootstrap";

// Third-party component styles
@import './../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import './../node_modules/@fortawesome/fontawesome-free/scss/solid';

/* Sticky footer styles
-------------------------------------------------- */
html {
    position: relative;
    min-height: 100%;
}

body {
    background-color: #eee;
    /* Margin bottom by footer height */
    margin-bottom: 2rem;
}

.footer {
    position: absolute;
    font-size: .8rem;
    bottom: 0;
    width: 100%;
    height: 2rem;
    line-height: 2rem;
    background-color: #ddd;
}

// Tables
.table td {
    vertical-align: middle;
}

.table th {
    vertical-align: middle;
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
